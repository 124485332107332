/// 데이터 정의
export const faq_list = [
    {
        index:0,
        title:'면접시 복장은 어떻게 되나요?',
        answer: '스마트스터디 게임즈에서는 자율적인 복장으로 근무하고 있습니다.\n면접 시 복장제한은 없으며 단정한 자유복장으로 참여하시면 됩니다.'
    },
    {
        index:1,
        title:'학력이나 외국어 제한 등에 대한 필터링은 없나요?',
        answer: '지원분야를 막론하고 학력에 대한 평가는 실시하지 않고 있습니다.\n\n외국어 능력도 점수보다는 실제로 업무에 들어갔을 때\n외국어 능력이 직접적으로 업무에 영향을 미치는 직렬에서만 평가하고 있습니다.'
    },
    {
        index:2,
        title:'서류전형의 평가기준은 무엇입니까?',
        answer: '작성해주신 자기소개서 및 관련된 경험과 역량을 중점적으로 평가합니다.\n공통적으로는 인재상에서도 언급되었듯이 지원분야에 대한 목표가 뚜렷하고, 다양한 경험을 통해 성장해나가시는 분을 선호합니다.'
    },
    {
        index:3,
        title:'과거 지원했던 탈락자인데 또 다시 지원해도 되나요?',
        answer: '지원하고자 하는 부문에 대해 자격요건만 맞으면 얼마든지 가능하며, 과거지원에 대한 불이익은 없습니다.\n또, 예전에 지원했던 분야가 아닌 다른 부문에 대한 지원도 가능합니다.'
    },
    {
        index:4,
        title:'결과 발표는 언제하나요?',
        answer: '제출일로부터 최대 15일 이내에 결과를 안내해드리고 있습니다. 합격시에는 이력서에 기재해주신 모바일 번호와 메일로 연락을 드리고,\n불합격시에는 메일로 결과를 안내 드리고 있으니 지원서 작성시 연락처 기재에 각별히 유의해 주시기 바랍니다.'
    },
    {
        index:5,
        title:'합격 후 수습기간이 있나요?',
        answer: '회사와 지원자 모두가  직무와 컬쳐핏이 맞는지 확인하는 3개월의 수습 기간이 있습니다.\n3개월 후, 평가를 거쳐 정식 합류를 결정하게 됩니다. 수습기간에도 처우에는 차별이 없습니다.'
    }
]

export const recruit_list = [
    {
        index:0,
        jobgroup:'신규프로젝트L',
        tasktitle: '모바일 게임 전투 기획자',
        isNew:true,
        filepath: '/pages/jdgameDesignerBattle/jdgameDesignerBattle',
        linkto: 'https://career.thepinkfong.com/o/98137'
    },
    {
        index:1,
        jobgroup:'몬스터슈퍼리그',
        tasktitle: '케릭터 원화가',
        isNew:true,
        filepath: '/pages/jdcharacterartistmsl/jdcharacterartistmsl',
        linkto: 'https://career.thepinkfong.com/o/106499'
    },
    {
        index:2,
        jobgroup:'신규프로젝트L',
        tasktitle: '모바일 게임 배경 원화가',
        isNew:true,
        filepath: '/pages/jdcharacterartistmsl/jdbgartist',
        linkto: 'https://career.thepinkfong.com/o/108868'
    },
    {
        index:3,
        jobgroup:'신규프로젝트L',
        tasktitle: '모바일 게임 3D 애니메이터',
        isNew:true,
        filepath: '/pages/jdcharacterartistmsl/jdanimator',
        linkto: 'https://career.thepinkfong.com/o/94372'
    },
    {
        index:4,
        jobgroup:'신규프로젝트L',
        tasktitle: '모바일 게임 TA',
        isNew:false,
        filepath: '/pages/jdta/jdta',
        linkto: 'https://career.thepinkfong.com/o/94373'
    }
]