
import React from "react";
import PropTypes from "prop-types";

import "./jdcharacterArtist.css";

class JdcharacterArtist extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="06e07d03-0791-4165-9dac-b46464fd17c5" className="jdcharacterArtist">        <div data-layer="c772531e-c63e-4265-8aa9-42d3902aec34" className="layer1">            <div data-layer="42efb896-8b4c-4213-a452-1626ec2b10da" className="thinkSmartStudyGamesIp2022RpgPvp">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="3372fcad-9b6d-45c0-b75d-0e42250284a4" className="x10981">                <div data-layer="e1988ffa-14ab-4c3f-8336-e53ea6aabc12" className="x6c08d8b2">서류 전형</div>
                <div data-layer="d7de5958-6e80-458f-b25b-4208718e713d" className="xb30ce94e">실무 면접</div>
                <div data-layer="8ec34d7a-3776-4500-9c1e-58ce483f00c1" className="x1b97e382">최종 합격</div>
                <div data-layer="8e937f43-988b-46b3-b825-42571099081e" className="x10980">                    <div data-layer="e92326c8-0d71-4ce1-ac8a-237a8a2feddd" className="x10979">                        <svg data-layer="86eceb69-eda3-4f38-86bb-1dd937eba63b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 896.958984375 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="f6eb3deb-ae10-47a7-96b4-3b0921d195ad" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="13df40bf-5a23-4c0d-81f2-9fb27f7bce6b" preserveAspectRatio="none" viewBox="-1.59765625 -1.59716796875 356.60546875 3.661865234375" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="921b6193-29c1-4557-bf83-7b7fde9b8cae" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 80.4296875 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="8e529c7e-4cee-4acf-b57c-2e982a0ffd69" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="c5122190-a6ae-4731-b66b-050dfafd7708" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="9ade5be4-d29c-4bcd-a5b7-faad89415f9f" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="dcd0c204-9b23-4669-9e96-cbff07a8bfad" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="25356682-4d83-4918-acb3-efac977c54aa" className="x0fe63d77">전형 절차</div>
</div>
                <div data-layer="cdcd5eb5-335a-421b-9fa7-81411e8b7825" className="x270cb711">임원 면접</div>
</div>
            <div data-layer="ef4b536a-7167-45de-bcc0-4dccb77426d3" className="x10983">                <div data-layer="fa024a0c-40ce-4dfd-9c9a-5688742aa34c" className="x597e7c7e"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="411bda24-2c5e-4cea-84ec-b57d69ad1bab" className="x28bd1d53">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="7e10b9f8-b834-4132-a6cb-5f169a3d8dfc" className="x867d96ee">지원 방법</div>
                <div data-layer="9a870c47-471e-48e2-b54e-0ef57d3503e2" className="linesc166901e">                    <svg data-layer="496ac3f0-cfb7-45d4-8779-bbe726414c4f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x14839994300"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="d9d90c51-d907-4173-89e0-9bb70746a3ae" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1427729dd84"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="e8b6e36f-4a16-433d-965e-f8833b8aac19" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149c094d50f"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="d0873983-1d02-4246-bc75-963f5adaef3a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143cfd11ff1"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="e3a8289a-3f43-4371-9a8f-41285922e67f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x1446bfad335"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="a54db2af-f7ba-48fc-893a-13c467cd1e91" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="21f87f64-e513-4809-a26e-f527fdf98cac" className="x10987">                <div data-layer="96c40ddf-59d7-40d6-984e-8859c54e495f" className="x2">스타일라이즈한 캐릭터 디자인이 가능하신 분<br />다양한 컨셉을 제시하고 새로운 시도를 추구하시는 분<br />게임개발 경력 2년 이상이신 분<br />자율과 책임기반의 자기주도적인 분<br />원활한 의사소통과 협업을 중요하게 생각하시는 분</div>
                <div data-layer="1c7827cb-90b1-477a-9369-f34e32dbebc2" className="x9d0de08f">자격 요건</div>
                <div data-layer="1f655627-ba12-40a0-ab83-a2586537c670" className="linesda7bf018">                    <svg data-layer="7ac4d845-1aba-4401-ad33-f6ce2ab7d9e3" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148fa44eb00"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="a0c66739-86e2-45b2-8d88-2b7e210e4017" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x14297190dcd"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="094e9171-c946-4416-a7be-9cef0d84a203" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149b8160a72"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="32738b0c-1756-4362-bb64-46adf7bfa1de" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1435c46c84f"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="efbc01d5-949b-47ca-885d-aa6ecc646d26" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144ab7ea4ce"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="77ce5195-0900-4c81-855d-0dab4e8d9445" className="x10985">                <div data-layer="21a888d4-ab94-4d8b-90b6-e81c1b5dc956" className="x70b04423">인체 및 아트 기본기가 좋으신 분<br />프로젝트 완수경험</div>
                <div data-layer="0d33ecd5-5e1f-48ee-afe3-af51abd068ee" className="x03f1fc11">우대 사항</div>
                <div data-layer="f2fdb178-a6cb-4f60-a31b-d193e5298b72" className="lines4ba04407">                    <svg data-layer="5b270d8d-1a7b-4039-a159-5d39cffb3a0a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148e9f655f7"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="3ba878d6-456c-4ea2-9e9b-c4abdca25a64" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1428fca5a85"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="4c006430-a729-40e1-baff-cdba531be675" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149c85359f0"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="721e9087-96bb-4b43-827f-ab8fb9cfc879" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143ae1def24"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="37079144-9d44-41ca-afec-a90bf0dceadc" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144dfb7b1f3"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="fabb9031-88d4-4d0d-bdf6-b83085b2b2de" className="x11012">                <div data-layer="56fb476c-4a7e-4869-8786-0abc16087101" className="xacc6f5e6">몬스터 슈퍼리그를 잇는 차기 프로젝트의 캐릭터 컨셉</div>
                <div data-layer="7ffc65d6-16ca-43cf-826d-689ba956fa93" className="lines">                    <svg data-layer="e5d1425a-15af-4734-ae69-2b5acc302658" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 248.837890625 3.1904296875" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="22ed75b7-8b9d-4a5c-a51a-2569febc654e" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 248.837890625 3.1904296875" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="399e5a4f-378a-4f39-8c90-014e53497628" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 310.4921875 3.1904296875" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="0d96fcd1-5ba5-4c8c-b033-63868957596d" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 310.4921875 3.1904296875" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="4a104798-94d8-444c-bd92-b37b2ec838f5" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 70.80859375 3.1904296875" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="09e9003d-405e-46d0-88dc-7ae117ad5bcb" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

JdcharacterArtist.propTypes = {

}

JdcharacterArtist.defaultProps = {

}


export default JdcharacterArtist;
          