
import React from "react";
import PropTypes from "prop-types";

import "./mjdgameDesignerBattle.css";

class MjdgameDesignerBattle extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="13f34ed3-af72-4c4e-bd19-cdf2fbb400d6" className="mjdgameDesignerBattle">        <div data-layer="16844d52-b8cc-423c-b3b7-bd74547a6baf" className="layer1">            <div data-layer="62c80474-0c9e-4c0b-9f9e-3049b02669e0" className="thinkSmartStudyGamesIp2022RpgPvp">“Think Smart Study Games!”<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2024년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="7b801f28-60a2-49c3-bb10-a564e59ed15e" className="x10981">                <div data-layer="c5c82616-94f7-48ad-a5e0-4120b7a499c3" className="x866a2248">서류 전형</div>
                <div data-layer="5e235a68-9481-40b7-89d5-6ec6218c6075" className="x45620390">실무 면접</div>
                <div data-layer="6b7e5f79-c27d-4823-a8db-229fe0d1f335" className="x79bee3e5">최종 합격</div>
                <div data-layer="6d1aba23-2fd5-4ec6-9013-9a845792f710" className="x10980">                    <div data-layer="d82ac4be-af00-4afe-bfd9-880bba79987c" className="x10979">                        <svg data-layer="9ff57ca3-0336-41b8-99a2-02fd1dfbd79e" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 501.19140625 3.19000244140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="fe18cf60-1c7d-4cd7-baf7-c2bec7104b70" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 126.19140625 3.19000244140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="5c97b055-247a-4bc3-84e8-a8c7e7cd5489" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 213.19140625 3.19000244140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="35c3217b-394e-4ef0-ab4e-0804d73f1cd4" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 53.19140625 3.19000244140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="f21f46fa-225d-4f85-bdcb-9cb1fe09f20d" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="3f7de241-05ca-4701-81c2-4014d6d97349" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="7f50b4f4-6958-46f7-8ef3-c3a73c3ed1f9" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="17657359-7482-4852-b486-4caeaa3a86a1" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="ad55e23d-cb6e-4455-b88b-7dab391eb407" className="xa12f689c">전형 절차</div>
</div>
                <div data-layer="1d5766e2-1814-41e6-af24-b60f99121754" className="x5e9347b2">임원 면접</div>
</div>
            <div data-layer="711ab1a6-eb7c-4ea1-b0de-d7149e1131db" className="x10983">                <div data-layer="d6a7ca04-7a02-4dc7-87f1-4dd51ae8aa8f" className="xa57f9607"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="62aca9ae-ec33-46f1-80ac-1a0c0307f62d" className="x50e8eff4">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="ccb31ac7-4430-46de-8070-9448992277ae" className="xd463f881">지원 방법</div>
                <div data-layer="b5906ab6-0e3b-49a5-9f3c-20ecb910fe37" className="linesbad65a7d">                    <svg data-layer="ef454583-3249-439c-81e4-38f16888d5d5" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 143.19140625 3.19000244140625" className="x14828c1c86f"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="205e32d5-712f-42d6-926c-40d7303b5124" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 143.19140625 3.19000244140625" className="x142c7daf999"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="7456e924-91a7-4eb7-a91a-755d6fed0524" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 173.19140625 3.19000244140625" className="x149aee04689"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="b2eeeda2-0e2b-4bde-a43f-6b1c0339bf4b" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 173.19140625 3.19000244140625" className="x1430e6ff2ea"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="b0d310e4-514f-4224-b971-220e42a80c5f" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 47.19140625 3.19000244140625" className="x1440c1379d8"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="8286909f-dfb2-46d2-9c11-1d54fa23466e" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="a6089c5e-d027-481a-aabe-011bfd80c821" className="x10987">                <div data-layer="4947c6c9-98f2-4279-94f7-de389a87b844" className="x5Rpg">전투 또는 캐릭터 기획 업무 경력 5년 이상<br />전투 시스템 또는 전투 콘텐츠 기획 경험<br />모바일 수집형 RPG 게임에 대한 이해도가 있으신 분<br />구체적인 업무 내용이 포함된 포트폴리오<br />자율과 책임기반의 자기주도적인 분<br />원활한 의사소통과 협업을 중요하게 생각하시는 분</div>
                <div data-layer="8530ec90-f29b-4fe9-9652-28fe99851482" className="x8e9437e7">자격 요건</div>
                <div data-layer="7a5493a0-a0c3-4773-a43d-a365fd3758cd" className="linesb150e3f3">                    <svg data-layer="7d0d772e-13d8-412b-8e11-bb2551523cf2" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148c40865c4"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="c9236a63-b084-43e9-ab17-567b8c683d58" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142cde2e126"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="c98f4d36-5ff9-449e-87c3-08d6e213677c" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149d69486bd"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="f63f4a75-5fa1-4325-8dc6-c2760e647fad" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143601e1cd2"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="4adfc019-e695-4688-b42d-d3745c4ade28" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144bff60d1b"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="43c8af3c-cc34-4cc2-b204-282681942f6c" className="x10985">                <div data-layer="036b383b-236c-4971-b5f3-805f2ea0a1f8" className="xRpgRpg">전투 레벨 디자인 경험<br />성장 밸런스 설계 경험<br />최근 출시한 캐릭터 수집형 모바일 RPG 게임을 다양하게 플레이<br />모바일 RPG/전략 게임 라이브 경험자<br />최초 개발 단계부터 런칭까지 경험자</div>
                <div data-layer="83cae5e3-c386-4c41-8ef0-109dbce80dbd" className="xd24c9822">우대 사항</div>
                <div data-layer="f029c324-d3b0-4b5f-9075-17cadd62560d" className="linesc9b82bb1">                    <svg data-layer="39009e84-7b94-4181-9c0f-90f7d392f152" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.189971923828125" className="x14823b7f56a"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="8e7580cb-2556-4a52-a9ca-c9b1fb567231" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.189971923828125" className="x14262cbfab6"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="03e4c4a3-b73b-4d57-a018-ca40a68973ff" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.189971923828125" className="x1491ced3269"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="668b6308-e32a-472e-8981-559488641e46" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.189971923828125" className="x143a3485fb7"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="9d872c0a-7188-4257-838f-4d353f330478" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.189971923828125" className="x144ef383363"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="2b704030-a5e4-41fd-9a52-d02acced81de" className="x11012">                <div data-layer="a96c6f17-fe30-491d-932c-9e9240486981" className="xRpgIp">캐릭터 수집형 RPG 기반 실시간 전투 시스템 및 전투 콘텐츠 기획<br />몬스터 슈퍼리그 IP 기반 캐릭터의 설정 및 스킬 기획</div>
                <div data-layer="dfd7b976-3308-4589-a53e-45305748acf6" className="x">주요 업무</div>
                <div data-layer="90b43a14-0a90-481a-970b-266a7f3f59d2" className="lines">                    <svg data-layer="39fa8dc6-67ec-413a-abd4-e6d33974298e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="d14f5d98-a5b2-44b9-afd6-61b46e9e35dc" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="12dee589-0f3b-4fd6-b4ae-fbb4eee5fa7e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="9cab6716-a3d5-450e-b9a8-711303ccf11c" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="16f6a988-c064-4f3c-be8b-43fe793a2b3d" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

MjdgameDesignerBattle.propTypes = {

}

MjdgameDesignerBattle.defaultProps = {

}


export default MjdgameDesignerBattle;
          