import React, { useRef, useState, useEffect, useCallback } from 'react';
import './recruit_impl.css';
import {faq_list, recruit_list} from '../../commondata';

import JdgameDesignBattle from '../jdgameDesignerBattle/jdgameDesignerBattle'
import JdBgArtist from '../jdbgArtist/jdbgArtist'
import JdAnimator from '../jdanimator/jdanimator'
import Jdcharacterartistmsl from '../jdcharacterartistmsl/jdcharacterartistmsl'
import Jdta from "../jdta/jdta"


const RecruitImpl = (props) => {
    
    const sizeHeaderH = 100;
    const sizeTopImgH = 780;
    const sizeRecruitHeader = 143; // 위 아래 마진 포함
    const sizeFaqHeader = 200; // 위 아래 마진 포함
    const sizeTailLink = 170; // 위 마진 포함
    const sizeFooter = 256; // 위 마진 포함
    const sizeRecruitItem = 98;
    const sizeFaqItem = 84;
    const recruitInnerW = 1200;

    const [idxfaqOpened, setFaqOpenedIndex] = useState(-1);
    const [faqDimension, setFaqDimension] = useState({width:0, height:0});
    const [faqAnsDimension, setFaqAnsDimension] = useState({width:0, height:0});
    const [faqQusDimension, setFaqQusDimension] = useState({width:0, height:0});

    const [idxrecruitOpened, setRecruitOpenedIndex] = useState(-1);
    const [refRecruitTitles, setRefRecruitTitles] = useState([]);
    const [recruitInnerDeimension, setRecruitInnerDimension] = useState({width:0, height:0});
    const [openedItemScale, setOpenedItemScale] = useState(1.0);
    const [refLinktos, setRefLinktos] = useState([]);

    const onSetFaqOpenedIndex = useCallback((idx, isReset=false) => {
        if(isReset===false && idxfaqOpened>-1 && idxfaqOpened<idx)
        {
            const y = window.pageYOffset - (faqQusDimension.height + faqAnsDimension.height + 16);
            window.scrollTo(0, y);
        }

        setFaqOpenedIndex(isReset ? -1 : idx);
    })
    const onSetRecruitOpenedIndex = useCallback((idx) => {
        const nextIdx = (idx===idxrecruitOpened) ? -1 : idx;
        if(nextIdx>-1 && idxrecruitOpened>-1 && idxrecruitOpened<nextIdx)
        {
            const y = window.pageYOffset - (recruitInnerDeimension.height*openedItemScale + 25);
            window.scrollTo(0, y);
        }
        
        setRecruitOpenedIndex(nextIdx);
    })

    const reffaq = useRef();
    const reffaqans = useRef();
    const reffaqqus = useRef();
    const refrecruitinner = useRef();
    useEffect(() => {
        if(reffaq.current != null)
        {
            setFaqDimension({
                width: reffaq.current.scrollWidth,
                height: reffaq.current.scrollHeight
            })
        }

        if(reffaqans.current != null)
        {
            setFaqAnsDimension({
                width: reffaqans.current.scrollWidth,
                height: reffaqans.current.scrollHeight
            })
        }

        if(reffaqqus.current != null)
        {
            setFaqQusDimension({
                width: reffaqqus.current.scrollWidth,
                height: reffaqqus.current.scrollHeight
            })
        }
        
    }, [idxfaqOpened]);

    useEffect(() => {
        
        if(refrecruitinner.current != null)
        {
            setRecruitInnerDimension({
                width: refrecruitinner.current.scrollWidth,
                height: refrecruitinner.current.scrollHeight
            })

            const width = refrecruitinner.current.scrollWidth;
            const ratio = (recruitInnerW<width) ? recruitInnerW / width : 1.0;
            setOpenedItemScale(ratio);
        }

    }, [idxrecruitOpened]);

    const rezieRef = useCallback(() => {
        if(refRecruitTitles==null || refRecruitTitles.length!==recruit_list.length)
        {
            setRefRecruitTitles((trefs) => 
                Array(recruit_list.length).fill().map((_, i) => trefs[i] || React.createRef())
            );
        }
    })
    rezieRef();
    
    useEffect(() => {
        rezieRef();
    }, [recruit_list.length]);
    
    const recursivelyMod = (children) => {
        return React.Children.map(children, (childNode) => {
            
            if (typeof childNode === "string")
            {
                return childNode;
            }
            if (typeof childNode.props.children === "string")
            {
                return React.cloneElement(childNode, [], recursivelyMod(childNode.props.children));
            }

            if(typeof childNode.props.className === "string" && (childNode.props.className.indexOf("recruititem") > -1 || childNode.props.className.indexOf("faqitem") > -1) )
            {
                // 여기서는 아무것도 그리지 않음
                return ("");
            }
            else if(childNode.props.className === "recruitlist")
            {
                let detail_height = (idxrecruitOpened>-1) ? recruitInnerDeimension.height*openedItemScale : 0; // detail 공간
                const baseTop = sizeHeaderH + sizeTopImgH + sizeRecruitHeader -102;
                let height = recruit_list.length*sizeRecruitItem/* 98*아이템갯수 */ + 154/* 38(빈공간) + 50(마진) + 66(버튼) */ + detail_height/* detail 공간 */;

                const iter = recruit_list.map((item, index) => {

                    const DetailContents = () => {
                        
                        const ch = recruitInnerDeimension.height*openedItemScale; // 50(버튼사이) + 66(버튼) + 20(위 공백)
                        const styled = {
                            position: 'relative',
                            top: `${index*sizeRecruitItem+120}px`,
                            height: `${ch}px`
                        };

                        if(index === idxrecruitOpened)
                        {
                            const left = (recruitInnerW - recruitInnerDeimension.width) * openedItemScale / 2.0;

                            return (
                                <div className="recruitexpanded" style={styled}>
                                    <div className="recruititemdetailmod">
                                        <div className="detailcontents" ref={refrecruitinner} style={{left: `${left}px`, align:'left', transform: `scale(${openedItemScale})`, whiteSpace: 'pre-line', wordBreak: 'break-all'}}>
                                            {index===0 && <JdgameDesignBattle />}
                                            {index===1 && <Jdcharacterartistmsl />}
                                            {index===2 && <JdBgArtist />}
                                            {index===3 && <JdAnimator />}
                                            {index===4 && <Jdta />}
                                        </div>
                                        <svg preserveAspectRatio="none" viewBox="0 -0.5 1199 1" className="bottomline" style={{top: `${recruitInnerDeimension.height*openedItemScale}px`}}><path d="M 0 0 L 1199 0"  /></svg>
                                    </div>
                                </div>
                            );
                        }
                        return ("");
                    };
                    const AddNewIcon = () => {
                        if(item.isNew)
                        {
                            const elew = (refRecruitTitles[index]!=null && refRecruitTitles[index].current!=null) ? refRecruitTitles[index].current.scrollWidth : 0;

                            return (
                                <div className="newicon" style={{left: `${elew+21}px`}}>
                                    <svg preserveAspectRatio="none" viewBox="0 0 24 24" className="circleicon"><path d="M 12 0 C 18.62741661071777 0 24 5.372583389282227 24 12 C 24 18.62741661071777 18.62741661071777 24 12 24 C 5.372583389282227 24 0 18.62741661071777 0 12 C 0 5.372583389282227 5.372583389282227 0 12 0 Z"  /></svg>
                                    <div className="textn">N</div>
                                </div>
                            )
                        }
                        return ("");
                    }
                    
                    const deg = (index === idxrecruitOpened) ? 180.0 : 0.0;
                    const rot = {
                        transform: `rotate(${deg}deg)`
                    };
                    const pos_item = (idxrecruitOpened<0 || index<=idxrecruitOpened) ? 0 : detail_height + 25;
                    const style_item = {
                        top: `${pos_item + 25/* title 밑에 마진(0번째 인덱스) */ + index*sizeRecruitItem}px`,
                        cursor: 'pointer'
                    }
                
                    return (
                        <div key={index} height={height}>
                        <div className="recruititem" style={style_item} onClick={() => onSetRecruitOpenedIndex(index) }>
                            <div className="tasktitle">
                                <div className="texttitle" ref={refRecruitTitles[index]}>{item.tasktitle}</div>
                            </div>
                            <div className="jobgroup">
                                <div className="texttitle">{item.jobgroup}</div>
                            </div>
                            <svg preserveAspectRatio="none" viewBox="0 -0.5 1199 1" className="bottomline"><path d="M 0 0 L 1199 0"  /></svg>
                            <AddNewIcon />
                            <div className="expandmore" style={rot}></div>
                        </div>
                        <DetailContents />
                        </div>
                    )
                    
                })
                // 여기서 array 갯수에 따라서 iteration 돌면서 그려야 함

                const styled = {
                    top: `${baseTop}px`,
                    height: `${height}px`
                };
                let link_url = (idxrecruitOpened>-1 && idxrecruitOpened<recruit_list.length) ? recruit_list[idxrecruitOpened].linkto : "https://career.thepinkfong.com/jobs";

                return (
                    <div className="recruitlistmod" style={styled}>
                        <a href={link_url} target="_Blank"><div className="buttonapply">
                            <div className="bgrect"></div>
                            <div className="textapply">지원하러 가기</div>
                        </div></a>
                    <svg preserveAspectRatio="none" viewBox="0 -0.5 1199 1" className="upperline"><path d="M 0 0 L 1199 0"  /></svg>
                        {iter}
                    </div>
                );
            }
            else if(childNode.props.className === "faqlist")
            {
                // 여기서 array 갯수에 따라서 iteration 돌면서 그려야 함
                const added = (idxrecruitOpened>-1) ? recruitInnerDeimension.height*openedItemScale : 0;
                const baseTop = sizeHeaderH + sizeTopImgH + sizeRecruitHeader + (sizeFaqHeader-35) + recruit_list.length*sizeRecruitItem;
                let accH = 0;
                
                const iter = faq_list.map((item, index) => {

                    if(index === idxfaqOpened)
                    {
                        const ch = faqQusDimension.height + faqAnsDimension.height + 100;
                        const itemstyled = {
                            top: `${sizeFaqHeader-124+accH}px`,
                            height: `${ch+1}px`,
                            cursor: 'pointer'
                        };
                        accH += ch;
                        
                        return (
                            <div className="faqitemopened" ref={reffaq} key={index} style={itemstyled} onClick={() => onSetFaqOpenedIndex(index, true)}>
                                <svg preserveAspectRatio="none" viewBox="0 -0.5 1199 1" style={{top: `${ch-1}px`}} className="bottomline"><path d="M 0 0 L 1199 0"  /></svg>
                                <div className="faqdetail">
                                    <div className="textdetail" ref={reffaqans}>{item.answer}</div>
                                </div>
                                <div className="faqtitleopened">
                                    <div className="texttitle" ref={reffaqqus}>{item.title}</div>
                                </div>
                                <div className="iconqopened">
                                    <svg preserveAspectRatio="none" viewBox="0 0 40 40" className="circleicon"><path d="M 20 0 C 31.04569625854492 0 40 8.954304695129395 40 20 C 40 31.04569625854492 31.04569625854492 40 20 40 C 8.954304695129395 40 0 31.04569625854492 0 20 C 0 8.954304695129395 8.954304695129395 0 20 0 Z"  /></svg>
                                    <div className="textq">Q</div>
                                </div>
                                <div className="iconaopened">
                                    <svg preserveAspectRatio="none" viewBox="0 0 40 40" className="circleicon"><path d="M 20 0 C 31.04569625854492 0 40 8.954304695129395 40 20 C 40 31.04569625854492 31.04569625854492 40 20 40 C 8.954304695129395 40 0 31.04569625854492 0 20 C 0 8.954304695129395 8.954304695129395 0 20 0 Z"  /></svg>
                                    <div className="texta">A</div>
                                </div>
                                <div className="expandmore"></div>
                            </div>
                        )
                    }
                    else
                    {
                        const ch = sizeFaqItem;
                        const itemstyled = {
                            top: `${sizeFaqHeader-124+accH}px`,
                            height: `${ch+1}px`,
                            cursor: 'pointer'
                        };

                        accH += ch;
                        return (
                            <div className="faqitemmod" key={index} style={itemstyled} onClick={() => onSetFaqOpenedIndex(index)}>
                                <svg preserveAspectRatio="none" viewBox="0 -0.5 1199 1" className="bottomline"><path d="M 0 0 L 1199 0"  /></svg>
                                <div className="faqtitle">
                                    <div className="txttitle" ref={reffaqqus}>{item.title}</div>
                                </div>
                                <div className="iconq">
                                    <svg preserveAspectRatio="none" viewBox="0 0 40 40" className="circleicon"><path d="M 20 0 C 31.04569625854492 0 40 8.954304695129395 40 20 C 40 31.04569625854492 31.04569625854492 40 20 40 C 8.954304695129395 40 0 31.04569625854492 0 20 C 0 8.954304695129395 8.954304695129395 0 20 0 Z"  /></svg>
                                    <div className="txtquestion">Q</div>
                                </div>
                                <div className="expandmore"></div>
                                <div className="itemrect"></div>
                            </div>
                        )
                    }
                })
                
                
                const styled = {
                    top: `${baseTop+added}px`,
                    height: `${faq_list.length*sizeFaqItem + sizeFaqHeader}px`
                };
                return (
                    <div className="faqlistmod" style={styled}>
                        <div className="texttitle">자주 하는 질문</div>
                        <svg preserveAspectRatio="none" viewBox="0 -1 1199.5 2" className="upperline"><path d="M 0 0 L 1199.5 0"  /></svg>
                        {iter}
                    </div>
                );
            }
            else if(childNode.props.className === "contentbody")
            {
                let added = (idxfaqOpened>-1) ? faqQusDimension.height + faqAnsDimension.height + 100 : faqQusDimension.height + 42;
                added += (idxrecruitOpened>-1) ? recruitInnerDeimension.height*openedItemScale : 0; // 50(버튼사이) + 66(버튼) + 20(위 공백)
                let height = sizeHeaderH + sizeTopImgH + sizeRecruitHeader + sizeFaqHeader + sizeTailLink;
                height += faq_list.length*sizeFaqItem + recruit_list.length*sizeRecruitItem;
                
                const styled = {
                    height: `${height+added}px`
                };
                
                return React.cloneElement(childNode, {style: styled}, recursivelyMod(childNode.props.children));
            }
            else if(childNode.props.className === "footer")
            {
                let added = (idxfaqOpened>-1) ? faqQusDimension.height + faqAnsDimension.height + 100 : faqQusDimension.height + 42;
                added += (idxrecruitOpened>-1) ? recruitInnerDeimension.height*openedItemScale : 0; // 50(버튼사이) + 66(버튼) + 20(위 공백)
                let height = sizeHeaderH + sizeTopImgH + sizeRecruitHeader + sizeFaqHeader + sizeTailLink + sizeFooter/2 - 20;
                height += faq_list.length*sizeFaqItem + recruit_list.length*sizeRecruitItem;

                const styled = {
                    top: `${height+added}px`
                };
                return React.cloneElement(childNode, {style: styled}, recursivelyMod(childNode.props.children));
            }
            else if(childNode.props.className === "recruit")
            {
                let added = (idxfaqOpened>-1) ? faqQusDimension.height + faqAnsDimension.height + 100 : faqQusDimension.height + 42;
                added += (idxrecruitOpened>-1) ? recruitInnerDeimension.height*openedItemScale : 0; // 50(버튼사이) + 66(버튼) + 20(위 공백)
                let height = sizeHeaderH + sizeTopImgH + sizeRecruitHeader + sizeFaqHeader + sizeTailLink + sizeFooter/2 - 20;
                height += faq_list.length*sizeFaqItem + recruit_list.length*sizeRecruitItem;

                const top = height+added+231; // 231 = footer size
                const styled = {
                    height: `${top}px`
                };
                return React.cloneElement(childNode, {style: styled}, recursivelyMod(childNode.props.children));
            }
            
            return React.cloneElement(childNode, [], recursivelyMod(childNode.props.children));
        })
    }

    return (
        recursivelyMod(props.children)
    );
}

export default RecruitImpl;