
import React from "react";
import PropTypes from "prop-types";

import "./mjdta.css";

class Mjdta extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="452c999e-3838-465b-824b-1f32d23aea0f" className="mjdta">        <div data-layer="b0a3102b-c10e-4104-bce6-c0e305f9b295" className="layer1">            <div data-layer="6487f0cc-bb13-4195-b065-d8d822f76774" className="thinkSmartStudyGamesIp2024Rpg">“Think Smart Study Games!”<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2024년, 스마트스터디게임즈는 신작 RPG 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="815f2e39-6aa0-4eca-a0ce-7db5d7c6551e" className="x10981">                <div data-layer="ec871ba7-68d0-4b02-9177-2f2961fda4b9" className="x35336192">서류 전형</div>
                <div data-layer="a05b580a-2b7b-4898-999b-8034d97a2ebb" className="x3d99dc52">실무 면접</div>
                <div data-layer="bf696863-d1fc-42d8-9bc9-b6acc892d447" className="x374e24b7">최종 합격</div>
                <div data-layer="500e16fd-4254-42f9-b884-0602498ec84d" className="x10980">                    <div data-layer="cfe7c80e-d5f1-446b-a639-9414db84229f" className="x10979">                        <svg data-layer="0ad954a7-ac78-4929-a162-b9df390104db" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 501.189453125 3.19000244140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="77831cc5-10a2-4018-90d4-feefa69663c5" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 126.189453125 3.19000244140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="ee83e817-0d23-4f92-bc56-d58ff113ef95" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 213.189453125 3.19000244140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="e43491b0-9e16-4a1d-b53a-fe50aec3c1c6" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 53.189453125 3.19000244140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="16406ea3-4dc4-4f96-9c65-5939201bd8dd" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="ae86cad1-9f81-4c04-918e-34935d3c0d8b" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="15e7be65-4210-4c40-aaaa-2da8b1872cbf" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="a308f9ad-cf09-40ac-9511-f64e1cf149f4" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="c97c2d8e-7fad-47bb-bbc1-00791c7791c3" className="x94718574">전형 절차</div>
</div>
                <div data-layer="75cab693-32f2-429d-8ea3-e65c8b14a8f2" className="x19020d03">임원 면접</div>
</div>
            <div data-layer="08bca2c6-e09f-41ff-935d-dc9c4cba11ca" className="x10983">                <div data-layer="e6fc169f-26a7-4eeb-a41c-795f8c65e5e7" className="xee9f1a97"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="fd43f453-9e28-43c6-8364-c5dbaf60c5dc" className="x0bafa9c2">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="a7a6e30e-7880-4b02-8296-eb5af57b9227" className="xa43ba38e">지원 방법</div>
                <div data-layer="814eb85c-c31d-4ac4-82e6-27db525df53b" className="lines5b933b45">                    <svg data-layer="57f4a548-9c41-4070-a374-dad7d2fb2daa" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x148a1243422"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="57790681-03a1-41a0-b66c-a4e69d9a2066" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x1427e2a3cb5"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="bcef5eb1-ac4e-40e8-8eb7-9d2298c714dd" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x1497ce93398"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="85a40b58-6b7e-42b2-a1be-ca6e9ac0574e" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x14383e619bb"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="9d6f8b08-e932-4e44-9822-e783a01f2f9a" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 47.189453125 3.19000244140625" className="x1446512e9fd"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="6f7aa3b3-7092-4d18-8547-4b8dcc1f4673" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="c505beb3-db55-4639-a35f-8a5ecb6a610b" className="x10987">                <div data-layer="2cf02d60-c43f-4275-aed0-1144ccb041c3" className="technicalArtist3Unity">Technical Artist 연관 경력 3년 이상이신 분<br />노드 기반 셰이더 분석 및 작성 능력이 있으신 분<br />Unity 최적화 지식 및 경험을 갖추신 분<br />자율과 책임기반의 자기주도적인 분<br />원활한 의사소통과 협업을 중요하게 생각하시는 분</div>
                <div data-layer="8cd5cc77-3c46-45d7-a91c-5989bf3629dd" className="x58ecbaa3">자격 요건</div>
                <div data-layer="66b94205-5ec1-4cac-a615-731291f5242a" className="linesd21e3280">                    <svg data-layer="7b87c8c6-8b5c-4c2c-a55c-9475ca28eb5d" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x14838bc2747"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="db6be6e0-670f-42fe-a106-c980b92f0133" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x142e55c6550"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="ef7e8b2d-f548-4373-b9b4-d84204d25dab" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x1496d728026"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="0c2d4f5f-998b-4a26-81ef-2fe716aeb53c" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x1433da8b026"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="48572ad8-1b16-476a-895e-5de5584ad4f1" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x1444faa4b7e"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="4778f606-5640-4a2c-94e1-dcb9a6ac6da9" className="x10985">                <div data-layer="122eccd9-4566-457a-a512-7e1c30c76038" className="unityUrp">Unity URP기반 프로젝트 참여 경험이 있으신분<br />카툰렌더링 기반의 프로젝트 참여 경험이 있으신 분<br />그래픽 파이프라인과 엔진 기능에 대한 이해도가 높으신분<br />개발 단계부터 출시까지 경험이 있으신 분<br />문제 해결결능력 / 문서 작업능력 / 커뮤니케이션 능력이 좋으신 분</div>
                <div data-layer="60f67bbc-e1b9-4f64-8d04-46d7118573d3" className="xb116f138">우대 사항</div>
                <div data-layer="93f609ea-f19a-4504-b50e-58701af60b53" className="lines00b0fdb9">                    <svg data-layer="262234a3-62a4-4925-8ba2-eb62665b4ac6" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x148ae160924"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="8b2fbe46-226c-44b6-8cda-dd52cd3664f3" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x142d701661d"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="a642b884-2b7f-4cf6-9518-201adf61860d" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x149114b4de7"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="96baae20-2a62-48ab-a02f-1c295ffe0eb2" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x14313d8312f"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="566a71d7-b5a5-48c3-a9c9-a9a574e0257b" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 47.189453125 3.19000244140625" className="x144a61bc168"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="d14b0111-136b-4028-b2fe-23815e33302e" className="x11012">                <div data-layer="77179dfc-cb2b-40a4-9d54-1b572c364c17" className="xTa">글로벌 타겟 모바일 프로젝트 라테시아의 TA 업무<br />아트팀 기술 지원 및 파이프라인 구축과 개선</div>
                <div data-layer="da3a20a8-9221-4581-9066-e52ad416a18f" className="x">주요 업무</div>
                <div data-layer="c93d19e3-93c5-492d-936a-72e332c395c3" className="lines">                    <svg data-layer="4f9389f1-66d7-4b2a-a545-e413230f8d54" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="c70e7a31-e7d9-4574-a291-04217a5c4dda" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="33a6a982-11c9-4b6b-9879-a86844e59309" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="4ad4e4b6-8b42-49cc-9819-53fe6a609487" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="77609530-e785-46b3-bbef-b5eae19a534b" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

Mjdta.propTypes = {

}

Mjdta.defaultProps = {

}


export default Mjdta;
          