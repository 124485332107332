
import React from "react";
import PropTypes from "prop-types";

import "./mjdcharacterArtist.css";

class MjdcharacterArtist extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="825b4547-8d97-44a8-8b54-fd74652a87a9" className="mjdcharacterArtist">        <div data-layer="f801aa8d-e0c6-4de3-b50c-cc98d873fb23" className="layer1">            <div data-layer="7d99c847-95ac-4bf4-be75-ad1d507221aa" className="thinkSmartStudyGamesIp2022RpgPvp">“Think Smart Study Games!”<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="2f29ddcc-11eb-4743-832b-9ced785574d4" className="x10981">                <div data-layer="2914ec66-1469-46d5-aaf8-eec4dc63ea46" className="x7c8e19ce">서류 전형</div>
                <div data-layer="fd88c9c7-3e77-4045-979f-fb16c39c1cd1" className="xf08c3f74">실무 면접</div>
                <div data-layer="fab64a42-2c67-4ce8-8adf-07c4d426ef0e" className="x5907b93a">최종 합격</div>
                <div data-layer="28fafc24-a562-4502-be51-84322b467171" className="x10980">                    <div data-layer="be0384dd-8e68-43a9-9250-20c2d5148806" className="x10979">                        <svg data-layer="765615b5-50a5-42f9-8fd4-ad5da1fd2ee9" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 501.19140625 3.18994140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="f3773143-9c04-4975-b94b-bf4612a496de" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 126.19140625 3.18994140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="06a6ea4d-86d5-4431-b344-3ce1c6de5799" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 213.19140625 3.18994140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="5fcb2e79-3e60-4b71-a6fe-c0e557c2b2e1" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 53.19140625 3.18994140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="8f42e97b-5d05-42ba-9fed-d24047938b05" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="5c5c7014-3f47-468c-b9c2-d4539371500d" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="0f65740a-9bff-4863-859b-e2135b3c0bed" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="35f7b1c2-81df-4b2b-ac7f-6ddf2f6000e2" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="29850a4a-8791-4d43-86e0-9fe444fbd8a8" className="xaf8fd9ce">전형 절차</div>
</div>
                <div data-layer="c85a43c0-f462-4b44-bb73-8260ae77fe8c" className="x7489e9f8">임원 면접</div>
</div>
            <div data-layer="08b3aaef-ac4b-4245-ac41-1eefa0f45cf0" className="x10983">                <div data-layer="06341340-62ad-4610-a2a9-74a2db4881e9" className="x7176c029"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="7acecbf5-f071-4921-a2ba-544d834eccdf" className="x1704da0b">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="bf516aa6-9126-496c-a063-ae732479512a" className="x14aab2c2">지원 방법</div>
                <div data-layer="df2030d8-56c1-41f0-8b0c-203289990d6d" className="lines7f5fd10d">                    <svg data-layer="ecbcb4ff-6a45-4080-8781-31d82b6b3477" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1487b54b8d9"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="459463bc-67c9-4b47-8307-31cebce0f964" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x14207952a77"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="a9a2911a-a372-4009-b02f-03cb2c3d74e1" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x1496b69e021"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="98abd211-b76d-44d5-8be9-e340c51daafc" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x1434137ad8d"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="73522e44-bb7c-4944-9292-4fc5bb568c46" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x14433aa9ca8"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="f0b4d2b7-3a10-4d69-a9cc-5b3f5e3d9d8d" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="68cb0b1e-cdfc-4dce-8e09-baa7f89dd33a" className="x10987">                <div data-layer="d0f3a4cd-315a-44a3-9c29-b558e0a2fe2e" className="x2">스타일라이즈한 캐릭터 디자인이 가능하신 분<br />다양한 컨셉을 제시하고 새로운 시도를 추구하시는 분<br />게임개발 경력 2년 이상이신 분<br />자율과 책임기반의 자기주도적인 분<br />원활한 의사소통과 협업을 중요하게 생각하시는 분</div>
                <div data-layer="d17326ea-e75b-46f3-8d97-73c0f1ed9af5" className="xaea9913f">자격 요건</div>
                <div data-layer="d68dd6de-81fb-4517-a9c8-600aacd9de62" className="lines1804f921">                    <svg data-layer="76234699-3e26-454b-ae33-343a42327848" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x14855fce4e0"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="bd7d5f21-8c3f-4600-add4-01a893a3b4c0" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x14248a4df10"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="af95aa1d-6026-43be-b3d8-9c3ec1841140" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x14988f8eb7c"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="58b480ec-2c68-4022-a494-f46e45ddd084" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x1431c6386c2"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="b4e1ab47-515d-497c-9d55-b4052250c1be" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144f110a0b6"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="23b3cc1d-cd5b-4561-b8c1-6997ca466846" className="x10985">                <div data-layer="5863c7b1-cfb3-4ede-a7d6-4e77d447d19b" className="x9b6a14d5">인체 및 아트 기본기가 좋으신 분<br />프로젝트 완수경험</div>
                <div data-layer="669ed1b1-b07e-4aa1-a0d0-91755e1575bf" className="xc9581872">우대 사항</div>
                <div data-layer="d1940b00-9907-41b9-826e-86a70d834dff" className="lines31f0f37f">                    <svg data-layer="cd9f2b71-1119-4bc1-917e-83d980aa0a2f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1481d09b00c"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="c9266180-b164-4a6a-a8d6-45940ccc513e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x14289a3b131"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="0fbe6091-eaec-4135-8a32-afe40497c2a2" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x1494c6568b0"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="f91ec5cb-3fcc-44ab-a0f5-f8dce8cb9662" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143b92c9895"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="8903f438-596e-46af-9475-d1e528b37750" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144c5324705"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="aac591e1-bed5-42f2-83c3-58a426bea75e" className="x11012">                <div data-layer="0b406b9f-9b40-43d7-987e-59b5d2f793b5" className="xcdb3b0c3">몬스터 슈퍼리그를 잇는 차기 프로젝트의 캐릭터 컨셉</div>
                <div data-layer="ccb6bc21-1abd-4ca2-a86b-7ffb52a35038" className="x">주요 업무</div>
                <div data-layer="971f61ac-5701-4787-a98e-cd4fc44d3912" className="lines">                    <svg data-layer="055890da-51d3-41ca-9c91-038cde198363" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="aacc6375-a7bc-4ef4-ac55-b6d7513504da" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="b6c8b282-7ef5-477b-9fca-e82e1b52c139" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="0ac11578-564f-4dd4-abb8-b7260ae0459f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="6827ba36-a3ce-4fa2-a0e5-ae1a21cb5177" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

MjdcharacterArtist.propTypes = {

}

MjdcharacterArtist.defaultProps = {

}


export default MjdcharacterArtist;
          