
import React from "react";
import PropTypes from "prop-types";

import "./jdcharacterModelermsl.css";

class JdcharacterModelermsl extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="50485dab-4d1c-404e-8981-99ccded98061" className="jdcharacterModelermsl">        <div data-layer="f919dd79-805c-4695-a623-158c91cec10b" className="layer1">            <div data-layer="51cab7c7-143a-435c-8125-508d7bdd19b3" className="thinkSmartStudyGamesIp2022RpgPvp">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="4c475e1b-a814-4682-9d8c-caca356b9204" className="x10981">                <div data-layer="0324d66a-12ea-4245-a045-65442247bae9" className="xdf71c759">서류 전형</div>
                <div data-layer="58479830-7052-420c-bf91-79fe94bc7da3" className="x4ab9222f">실무 면접</div>
                <div data-layer="9045f160-a5ab-4a83-bc49-617379d6b5c1" className="xcd6df75d">최종 합격</div>
                <div data-layer="5baad52c-96a5-4b48-884c-5ea6efe0a412" className="x10980">                    <div data-layer="7b0cd468-65b2-48b7-8914-c2d7eb1335f0" className="x10979">                        <svg data-layer="000ec8d8-3cc2-48f6-9cee-5276c8d7dd0c" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 896.95703125 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="a848df99-fe9f-4e82-ad42-601ef6b05e3f" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="636bcdfd-de64-4eae-b877-a735c120a147" preserveAspectRatio="none" viewBox="-1.5966796875 -1.59710693359375 356.603515625 3.6617431640625" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="65073247-d3b1-4b5d-8e6c-48267f67cba1" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 80.427734375 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="2e748415-09e0-48cb-946e-e9718ca147c2" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="0272e531-141c-4951-b381-543a172b0fd7" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="8e49902a-6924-49ff-853b-0a28266682f6" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="9d169095-8169-4f0f-9963-d968e4126baf" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="06990d64-f7de-452c-9b43-f12819bb769a" className="xd80bf668">전형 절차</div>
</div>
                <div data-layer="4be2bb54-8a27-46dc-9ef6-296bede8b567" className="x92218c4a">임원 면접</div>
</div>
            <div data-layer="df5bb622-cfb4-4922-a83b-8fff102873a5" className="x10983">                <div data-layer="e31629b3-8a43-4313-8b88-4a26544db868" className="x0a5263d7"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="90d6f32b-0284-4d6b-95fe-a2d0fee3982f" className="xc326f966">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="e8842e66-be1f-4b1b-9c26-aaed8706c951" className="xcd086b2e">지원 방법</div>
                <div data-layer="962ce00c-c795-4a36-aa5b-21cdee8583ec" className="lines58469de3">                    <svg data-layer="ca60e7ad-849b-4b72-bbb1-27423efba8a2" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x148e467c6ca"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="a463b722-f7a4-4be7-91a7-1a5f065cee76" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142c7a6d169"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="1e300bfe-8a1d-4bce-b6ba-b18fcb080c1e" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x149a3a88303"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="d277cefc-82ac-4ea7-91e3-9b29836d6284" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x1431f326f2a"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="95e6b672-69d1-4fe5-a692-62b3feccb9eb" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x144ad26eeec"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="c1f16ce4-7b19-49c2-b592-c2991d8ded17" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="9ac1663d-2c5c-4329-9ddb-5d02bcc0f0ff" className="x10987">                <div data-layer="576ceb97-357e-45dd-80d8-df29b628ca4b" className="x3dsMax">3ds MAX 사용에 능숙하고, 캐주얼한 매핑 작업이 가능하신 분<br />재패니메이션풍의 카툰 스타일에 익숙하신 분 <br />다양한 체형의 캐릭터나 몬스터 작업이 익숙하신 분 </div>
                <div data-layer="6c75c417-9235-41aa-9c83-28012d0a07cb" className="x9fb1e371">자격 요건</div>
                <div data-layer="7e96101c-b664-4d5d-b5a0-de995486c73b" className="lines4e4eab9a">                    <svg data-layer="a6a8b265-da96-4aa3-82e1-dc2fb4a64a6d" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x148ae520c96"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="e44fa91d-28a2-4624-aa28-909edd15628a" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142c608be37"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="bd6819bb-d30c-48ec-aa2e-f10c1cd93864" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x149112c6af1"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="35b1f101-3cd0-4d0e-a321-d74c758ec818" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x1434b6b5bcb"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="f496455b-ea24-429c-b1f9-a3054ffb8ede" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x14437ea48ef"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="60b6774d-1839-40b3-b98e-69e5ba21e177" className="x10985">                <div data-layer="7f384c8b-10c3-4615-a84d-0df5128e74c6" className="xUnity">평소 애니메이션을 즐겨보시는 분<br />Unity 사용에 거부감이 없으신 분<br />관련학과 전공자<br />원활한 커뮤니케이션이 가능하신 분 </div>
                <div data-layer="b0cbcadc-bf3a-4de3-8e1c-dc49b8838e91" className="xc9b65776">우대 사항</div>
                <div data-layer="fb5a1237-ab8f-4868-8833-1ab1d1a286c7" className="linesc19ab513">                    <svg data-layer="87691ecd-a489-4c01-9678-1512edfb0cad" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x148ddf719ad"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="4a3d0a8c-ea64-4dcc-9257-2d6aab1467ff" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x1421be11a8a"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="6573a3e8-b559-450b-a0f9-3df34d19076e" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x149114440e6"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="84ec81ac-6d49-4258-957f-34f2340ede0f" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x1433bc9a7c4"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="c53a3e7b-fd16-4b02-b028-558282dba21d" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x1448120418e"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="985c4d4d-1f1e-4a90-9e84-eae3cb1bcd0f" className="x11012">                <div data-layer="22f5d5f9-5b38-4510-bb8d-b01b5eef54a9" className="xRpg">글로벌 원빌드 수집형 RPG ‘몬스터 슈퍼 리그’의 캐릭터 및 몬스터의 모델링</div>
                <div data-layer="10c2fc7f-3863-44bd-b93d-f49fe7bb9389" className="lines">                    <svg data-layer="9294bf82-6bfc-4121-9899-9ca245ed20e4" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="19271765-7d72-4970-858a-ff8092ecadeb" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="5247fc10-f02e-4c62-ab4b-b46ede1831e6" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="c9aab417-856a-4696-86d2-4865ab7a8f73" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="5a077501-4e67-44c1-b1bc-00fa3bced7fa" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="de7f0018-87bd-417a-9530-c2fac95634dd" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

JdcharacterModelermsl.propTypes = {

}

JdcharacterModelermsl.defaultProps = {

}


export default JdcharacterModelermsl;
          